@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #18191F;
  overflow-x: hidden;
}

body {
  line-height: 1.5;
  font-family: 'Inter', 'Roboto', sans-serif;
  font-display: swap;
  font-weight: normal;
  color: #fefefe;
  width: 100vw;
  overflow-x: hidden;
}

table.privacy {
  border-collapse: collapse;
}

table.privacy th,
table.privacy td {
  border: 1px solid #aaaaaa;
  padding: 10px;
}
